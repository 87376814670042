import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';
import { API_SERVER, post, setProgess } from './utils';
import html2canvas from "html2canvas"
import SignatureCanvas from 'react-signature-canvas'

export default function Agreement(props) {
    const exportRef = useRef();
    const [paymentTerms, setPaymentTerms] = useState({});
    const [state, setState] = props.state;
    const [monthlyPayments, setMonthlyPayments] = useState(true);
    const [drawn, setDrawn] = useState(false);

    async function upload() {
        let el = exportRef.current;
        let canvas = await html2canvas(el);
        if (canvas.width > 1280) {
            let aspect = canvas.width/canvas.height;
            let canvas2 = document.createElement('canvas');
            canvas2.width = 1280;
            canvas2.height = 1280 / aspect;
            canvas2.getContext('2d').drawImage(canvas, 0, 0, canvas2.width, canvas2.height);
            canvas = canvas2;
        }
        let data = canvas.toDataURL('jpg');
        await post(`${API_SERVER}/api/agreement`, {phone: localStorage.getItem('phone'), image: data});
    }

    async function onComplete() {
        setDrawn(false);
        await upload();
        setState('complete')
        setProgess('complete');
    }

    useEffect(_ => {
        let pt = JSON.parse(localStorage.getItem('paymentTerms'));
        if (pt?.monthlyPayment == 0) setMonthlyPayments(false);
        setPaymentTerms(pt || {});
    }, []);

  return (
    <Container style={{paddingLeft: 50, paddingRight: 50}} ref={exportRef}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 20 }}>
          Agreement
        </Typography>

        {monthlyPayments ? (<>
        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        The payment has been made and is non-refundable.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        You may cancel your class ahead of time and avoid monthly payments, but the deposit is non-refundable. Not attending the class without prior notice of cancellation does not nullify this agreement and you will still be responsible for the entire balance.
        </Typography>
        </>) : null}

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            Aura Premium Vinyl LLC ("Aura") offers professional vinyl wrap training services. By proceeding with payment, you acknowledge and agree to the following terms:
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            1. All payments made to Aura, including deposits and course fees, are non-refundable.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            2. Class schedules must be confirmed in advance. Cancellations must be submitted in writing prior to the scheduled class date to avoid additional charges. The initial deposit remains non-refundable.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            3. Failure to attend a scheduled class without proper cancellation notice will result in forfeiture of the payment, and you will remain responsible for any outstanding balance.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            4. Aura reserves the right to reschedule classes with reasonable notice. While we strive to maintain scheduled dates, circumstances may require adjustments to the training calendar.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            5. Training materials and methods provided are for educational purposes only. Aura makes no guarantees regarding future income or business success.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
            By proceeding, you confirm that you have read, understood, and agree to these terms and conditions.
        </Typography>

        <SignatureCanvas penColor='black'
              onEnd={_ => setDrawn(true)}
              canvasProps={{width: 300, height: 100, className: 'sigCanvas'}} />

        <Typography>Sign your name above</Typography>

        <Button variant='contained' onClick={onComplete} disabled={!drawn} sx={{ mb: 10, mt: 3 }}>AGREE</Button>
    </Container>
  );
}