import React, { useEffect, useState } from 'react';
import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';
import { query, setProgess } from './utils';

export default function PaymentTerms(props) {
  console.log('full param value:', query('full')); // Debug log
  
  const [state, setState] = props.state;
  const [days, setDays] = useState(5);
  const [downPayment, setDownPayment] = useState(25);
  const [downAmount, setDownAmount] = useState(0);
  const [customPayment, setCustomPayment] = useState(false);

  useEffect(_ => {
    let total = customPayment ? customPayment : (days * 300);
    let down = Math.round(total * (downPayment/100));
    setDownAmount(down);

    props.terms[1](prevState => ({
      ...prevState,
      downPayment: down,
      monthlyPayment: 0,
      months: 0,
      days: days,
      total: total
    }));

  }, [days, downPayment, customPayment]);

  useEffect(_ => {
    if (query('ag')) {
      setCustomPayment(Number(query('ag')));
    }
    // Only set to 100% if 'full' parameter is present and truthy
    const fullParam = query('full');
    console.log('Setting full payment:', fullParam); // Debug log
    if (fullParam && fullParam !== false && fullParam !== 'false') {
      setDownPayment(100);
    }
  }, []);

  useEffect(_ => {
    setDays(Number(query('d')));
  }, [customPayment]);

  const renderDetails = _ => {
    if (downAmount == 0) return null;
    
    if (downPayment === 100) {
      return (<>
          <Typography gutterBottom>${downAmount} paid in full today.</Typography>
      </>);
    }
    
    return (<>
        <Typography gutterBottom>${downAmount} down payment due today.</Typography>
    </>);
  };

  return (
    <Container style={{paddingLeft: 50, paddingRight: 50}}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 20 }}>
          Payment Terms
        </Typography>

      {customPayment ? 
        <>
          <Typography style={{marginBottom: 20}}>Custom Payment: ${customPayment}</Typography>
        </> :
        <>
          <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
            <InputLabel>Days</InputLabel>
            <Select
              value={days}
              onChange={(e) => setDays(e.target.value)}
              label="Days"
            >
              <MenuItem value={3}>3 days ($900)</MenuItem>
              <MenuItem value={4}>4 days ($1200)</MenuItem>
              <MenuItem value={5}>5 days ($1500)</MenuItem>
            </Select>
          </FormControl>
        </> 
      }

      {query('full') === false && (
        <>
          <Typography gutterBottom>Down Payment ({downPayment}%)</Typography>
          <Slider
            value={downPayment}
            onChange={(e, newValue) => setDownPayment(newValue)}
            step={5}
            marks
            min={25}
            max={100}
            valueLabelDisplay="auto"
          />
        </>
      )}

      {renderDetails()}

      <Button variant='contained' onClick={_ => {
        setState('downpayment')
        setProgess('downpayment', {paymentTerms: props.terms[0]});
      }} sx={{ mt: 3 }}>Confirm</Button>
    </Container>
  );
}