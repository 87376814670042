import logo from './logo.svg';
import './App.css';
import AddressForm from './AddressForm';
import PaymentTerms from './PaymentTerms';
import Agreement from './Agreement';
import Complete from './Complete';
import { Typography } from '@mui/material';
import DownPayment from './DownPayment';
import { useEffect, useState } from 'react';
import { API_SERVER, loadProgress, post, query } from './utils';
import MonthlyPayment from './MonthlyPayment';

function App() {
  const state = useState(localStorage.getItem('currentState') || 'info');
  const paymentTerms = useState({
    days: 5,
    downPayment: 0,
    monthlyPayment: 0,
    months: 12,
    total: 0
  });
  const [paymentObj] = paymentTerms;
  const [currentState, setCurrentState] = state; 

  const userInfoState = useState({
    firstName: '', lastName: '', address1: '',
    address2: '', city: '', state: '',
    zip: '', phone: ''
  });
  const [userInfo] = userInfoState;

  useEffect(() => {
    if (query('full')) {
      localStorage.clear();
      setCurrentState('info');
    }
  }, []);

  function renderState() {
    if (currentState == 'info') {
      return (<>
        <AddressForm info={userInfoState} state={state} submit={uploadUserData} />
      </>);
    }

    if (currentState == 'terms') {
      if (localStorage.getItem('paidDeposit') == 'yes') setCurrentState('monthlyPayment');
      if (localStorage.getItem('linkedBank') == 'yes') setCurrentState('agreement');
      return (<>
        <PaymentTerms terms={paymentTerms} state={state} />
      </>);
    }

    if (currentState == 'downpayment') {
      if (localStorage.getItem('paidDeposit') == 'yes') setCurrentState('agreement');
      return (<>
        <DownPayment state={state} terms={paymentTerms} />
      </>);
    }

    if (currentState == 'monthlyPayment') {
        let data = JSON.parse(localStorage.getItem('paymentTerms'));
        if (localStorage.getItem('odp') == 'yes') {
          data.monthlyPayment = 0;
          paymentTerms.monthlyPayment = 0;
          localStorage.setItem('paymentTerms', JSON.stringify(data));
        }
        if (data.monthlyPayment == 0) setCurrentState('agreement');
      return (<>
        <MonthlyPayment state={state} />
      </>);
    }

    if (currentState == 'agreement') {

      return (<>
        <Agreement state={state} paymentTerms={paymentObj} />
      </>);
    }

    if (currentState == 'complete') {

      return (<>
        <Complete state={state} />
      </>);
    }
  }

  useEffect(_ => {
    loadProgress();
    if (query('payment_intent')) setCurrentState('downpayment');
    if (query('cmp')) {
      localStorage.setItem('odp', null);
      localStorage.setItem('paymentTerms', JSON.stringify({days: 3, downPayment: 225, monthlyPayment: 56, months: 12}));
      setCurrentState('monthlyPayment');
    }
  }, [currentState]);

  useEffect(_ => {
    if (query('odp')) localStorage.setItem('odp', 'yes');
    else if (!query('payment_intent_client_secret')) localStorage.setItem('odp', 'no');
  }, []);

  async function uploadUserData() {
    if (!userInfo.firstName.length) return;
    localStorage.setItem('phone', userInfo.phone);
    let req = await post(API_SERVER+'/api/createUser', userInfo);
    let {user} = await req.json();
    loadProgress(user);
  }

  return (
    <div className="App">
      <Typography variant="h4" gutterBottom style={{marginTop: 20}}>
        Aura Wrap Academy
      </Typography>
      {renderState()}
    </div>
  );
}

export default App;
